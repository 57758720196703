// import '$Styles/components/menu.less';
import React from 'react';
import WeatherDisplay from '$Scripts/components/weather';
import CurrencyDisplay from '$Scripts/components/currency';
import SearchBoxDisplay from '$Scripts/components/searchbox/searchbox';

export default function HeaderElements(props) {
    return (
        <>
            <SearchBoxDisplay title={props.config.searchbox.title} lang={props.config.lang}
                              url={props.config.search_url} timeout="1000"/>
            <CurrencyDisplay url="/misc/json/currency.json"/>
            <WeatherDisplay url="/misc/json/weather.json" lang={props.config.lang}
                            city={getCookie("weatherCity")}/>
        </>
    )
}
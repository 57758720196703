import React from "react";
import {inViewport} from "$Scripts/functions";
import styles from './stream.module.less';

export default class StreamFooter extends React.Component {
    constructor(props) {
        super(props);
        this.timer = null;
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll.bind(this));
    }

    handleScroll() {
        if (this.props.autoload && this.refs.footer && !this.props.progress) {
            if (inViewport(this.refs.footer, true)) {
                if (!this.timer) {
                    this.timer = setTimeout(() => {
                        this._handleClick();
                        clearTimeout(this.timer);
                        this.timer = null;
                    }, this.props.autoloadTimeout || 1000);
                }
            } else {
                clearTimeout(this.timer);
                this.timer = null;
            }
        }
    }

    _handleClick() {
        if (typeof this.props.onDoLoad === 'function') {
            this.props.onDoLoad(this.props.item);
        }
    }

    render() {
        const style = {
            float: 'left',
            width: '100%',
            cursor: 'pointer'
        };
        if (!this.props.showConfig.archive) {
            if (this.props.showConfig.next) {
                return (
                    <div
                        className={styles.loadMore}
                        ref="footer"
                        style={style}
                        onClick={this._handleClick.bind(this)}>
                        {this.props.children}
                    </div>
                )
            }
            if (this.props.showConfig.progress) {
                return (
                    <div className="rectangle-bounce">
                        <div className="rect1"/>
                        <div className="rect2"/>
                        <div className="rect3"/>
                        <div className="rect4"/>
                        <div className="rect5"/>
                    </div>
                )
            }
            return null;
        }
        return (
            <a href={this.props.archive_url}
               className={this.props.className}
               style={style}>
                {this.props.children}
            </a>
        )
    }
}

import React, {useEffect, useState} from 'react';
import Dropdown from "$Scripts/components/dropdown/dropdown";

export default function CurrencyWidget(props) {
    const [currentKey, setCurrentKey] = useState('USD');
    const [dropdown, setDropdown] = useState(false);
    const [data, setData] = useState([]);


    useEffect(() => {
        fetch(props.url)
            .then(response => response.json())
            .then((data) => {
                setData((prevState) => data);
            }).catch(error => console.error('CurrencyWidget exception:', error));
    }, []);

    let elems = Object.keys(data).map((key) => {
        return (
            <li key={key} onClick={() => _handleItemClick(key)}>
                {key} ({data[key] ? data[key][0] : ''})
            </li>
        );
    });

    function _handleItemClick(item) {
        setDropdown(false);
        setCurrentKey(item);
    }

    return (
        <Dropdown
            active={dropdown}
            title={
                <span onClick={() => setDropdown(!dropdown)}>
                    {currentKey} {data[currentKey] ? data[currentKey][0] : ''}
                </span>
            }
            content = {
                <ul>
                    {elems}
                </ul>
            } />
    )
}
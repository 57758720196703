// import '!css-loader?{"url":false,"sourceMap":true}!less-loader?{"sourceMap":true}!./desktop_stream.less';
import React, {useEffect, useState} from 'react';
import {usePrevious} from "$Scripts/functions";

import StreamContentItems from './streamContentItems';
import StreamBlankContentItems from './streamBlankContentItems';
import StreamFooter from "./streamFooter";
import StreamTitle from "./streamTitle";

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import desktopStyles from './stream.module.less';

export default function DesktopStreamBlock(props) {
    const [containerHeight, setContainerHeight] = useState(window.innerHeight);

    const [selected, setSelected] = useState("news");
    const prevSelected = usePrevious(selected);

    const [data, setData] = useState({});
    const prevData = usePrevious(data);


    const [displayState, setDisplayState] = useState({
        show: false, showNext: true, showArchive: false, showProgress: true
    });

    const [chapters] = useState(window.gk.navbar.chapters.reduce((chapters, chapter) => {
        return Object.assign({}, chapters, {
            [chapter.ID]: chapter
        });
    }));

    const [regions] = useState(window.gk.navbar.regions.reduce((regs, reg) => {
        const prev = Object.assign({}, regs, {
            [reg.ID]: {'TITLE': reg.TITLE_REGION, 'HREF': reg.HREF_REGION}
        });
        let si = {};
        if (reg.subitems) {
            si = reg.subitems.reduce((subItems, subItem) => {
                return Object.assign({}, subItems, {
                    [subItem[2]]: {
                        'TITLE': subItem[0],
                        'HREF': subItem[1]
                    }
                });
            }, {});
        }
        return Object.assign({}, prev, si);
    }));

    let list_refs = {};

    function _updateDimensions() {
        // setDisplayState((prev) => Object.assign({}, prev, {show: window.innerWidth > 1000}));
    }

    function _loadNextPage(item) {
        const itemConfig = props.types.find(itm => {
            return itm['key'] === item;
        });
        const params = Object.assign({},
            itemConfig.params, {
                page: data[item] ? data[item].page : itemConfig.params.page
            });
        const url = (props.lang === 'ukr') ? props.url : '/' + props.lang + props.url;
        const request = url + "?" + (new URLSearchParams(params)).toString();

        setDisplayState((prevState) => {
            return Object.assign({}, prevState, {
                showNext: false,
                showProgress: true
            });
        });

        fetch(request)
            .then(response => response.json())
            .then(({page, items, type, banners}) => {
                setData((prevData) => {
                    const prevItems = prevData[item] ? prevData[item]['items'] || [] : [];
                    const singleItem = {
                        page: page + 1,
                        items: [].concat(prevItems, items),
                        banners: banners,
                    };
                    return Object.assign({}, prevData, {
                        [item]: singleItem
                    });
                });

                setDisplayState(prev => Object.assign({}, prev, {showNext: true, showProgress: false}));

                if (typeof props.onNextPage == 'function') {
                    props.onNextPage.call(this, {
                        page: page,
                        type: type,
                        viewtype: 'desktop'
                    })
                }
                // scrollRefs[item].update();
            })
            .catch(error => console.error("Desktop stream exception:", error));
    }


    // useEffect(() => {
    //     if (displayState.show) {
    //         $(window).on('resize', _updateDimensions);
    //     } else {
    //         $(window).off('resize', _updateDimensions);
    //     }
    // }, [displayState]);

    useEffect(() => {
        if (prevSelected !== selected) {
            _loadNextPage(selected);
        }
    }, [selected]);

    function _handleTabClick(item) {
        setSelected(item);
    }

    function _renderContent() {
        if (!data[selected]) {
            const div_style = {
                display: 'block',
            };
            const wrapperStyle = {
                marginBottom: '120px',
            }
            return (
                <div>
                    <StreamBlankContentItems
                        numItems="10"
                        itemType="desktop"
                        className="items">
                    </StreamBlankContentItems>
                </div>
            )
        }
        const keys = Object.getOwnPropertyNames(data);
        return (keys.map((item) => {
            const div_style = {
                display: selected === item ? 'block' : 'none',
            };
            const wrapperStyle = {
                marginBottom: '120px',
            }
            const showConfig = {
                archive: displayState.showArchive,
                progress: displayState.showProgress,
                next: displayState.showNext
            };

            const items = data[item].items.map(article => {
                return Object.assign({}, article, {
                    chapter: chapters[article.parent],
                    region: regions[article.region]
                });
            });
            return (
                <div>
                    <StreamContentItems
                        onAfterDisplayItems={props.onAfterDisplayItems}
                        items={items}
                        banners={data[item].banners}
                        itemType="desktop"
                        className={desktopStyles.items}
                    >
                    </StreamContentItems>
                    <StreamFooter
                        item={item}
                        showConfig={showConfig}
                        autoload={true}
                        autoloadTimeout={500}
                        className={desktopStyles.loadmore}
                        onDoLoad={_loadNextPage}
                    >
                        Ще новини
                    </StreamFooter>
                </div>
            )
        }));
    }

    return (
        <div className={desktopStyles.desktopStream}>
            <StreamTitle className="filter"
                         titles={props.types}
                         selected={selected}
                         onChange={_handleTabClick}>
            </StreamTitle>
            <PerfectScrollbar>
                {_renderContent()}
            </PerfectScrollbar>
        </div>
    )
};


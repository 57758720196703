import React from 'react';
import SearchBoxDisplay from '$Scripts/components/searchbox/searchbox';
import LanguageDisplay from '$Scripts/components/language';

import navStyles from "./nav.module.less";

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

function GeoSubItems(props){
    if(props.items){
        const subitems = props.items.map(item => {
            return (
                <li key={item[1]}
                    onClick={() => props._handleItemClick(item[1])}
                >
                    <a href={item[1]}>{item[0]}</a>
                </li>
            )
        });
        return (
            <ul className={navStyles.geoSubItems}>
                {subitems}
            </ul>
        )
    }
    return null;
}

export default function NavPanel(props){
    function _handleItemClick(href) {
        window.location.href = href;
    }

    const geoItems = props.config.navbar.regions.map(item => {
        return (
            <li key={item.HREF_REGION} className={navStyles.geoItem}>
                    <span className={navStyles.geoItemTitle}>
                        <a href={item.HREF_REGION}>{item.TITLE_REGION}</a>
                        <i className="fa-solid fa-caret-down"></i>
                    </span>
                <GeoSubItems
                    key={item.HREF_REGION}
                    items={item.subitems}
                    // onItemClick={() => _handleItemClick()}
                />
            </li>
        )
    });
    const chapters = props.config.navbar.chapters.map(item => {
        return (
            <li key={item.HREF}>
                <a href={item.HREF}>{item.TITLE}</a>
            </li>
        )
    });
    
        return (
            <PerfectScrollbar className={navStyles.navPanel}>
                <div>
                    <LanguageDisplay config={props.config} icon="true"/>
                    <SearchBoxDisplay title={props.config.searchbox.title}
                                      lang={props.config.lang}
                                      url={props.config.search_url}
                                      timeout="250"
                                      className="nav-panel__searchbox" />
                </div>
                <ul className={navStyles.geoItems}>
                    {geoItems}
                </ul>
                <ul className={navStyles.chapterItems}>
                    {chapters}
                </ul>
            </PerfectScrollbar>
        )
}
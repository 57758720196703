import React, {useState} from 'react';
// import '!css-loader?{"url":false,"sourceMap":true}!less-loader?{"sourceMap":true}!./language.less';

import Dropdown from '$Scripts/components/dropdown/dropdown';
// import style from './language.module.less';

export default function LangugageDisplay(props) {
    const [active, setActive] = useState(false);

    const elems = Object.keys(props.config.langs).map(function (key) {
        return (
            <li key={key} onClick={() => window.location.href = props.config.langs[key][1]}>
                {props.config.langs[key][0]}
            </li>
        )
    });


    return (
        <Dropdown
            active={active}
            title={
                <span onClick={() => setActive(!active)}>
                    {props.config.langs[props.config.lang][0]}
                </span>
            }
            content={
                <ul>
                    {elems}
                </ul>
            }
        />
    )
}
import {useEffect, useRef, useState} from "react";

export function inViewport (elem, debug = false) {
    const bounding = elem.getBoundingClientRect();
    const screenHeight = window.innerHeight || document.documentElement.clientHeight;
    const screenWidth = window.innerWidth || document.documentElement.clientWidth;
    const isTop = bounding.top > 0 && bounding.top < screenHeight;
    const isLeft = bounding.left > 0 && bounding.left < screenWidth;
    const isBottom = bounding.bottom < screenHeight && bounding.bottom >     0;
    const isRight = bounding.right < screenWidth && bounding.right > 0;
    return (isTop  || isBottom);
};

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    },[value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
}

export function useDebounce(callback, delay){
    const latestCallback = useRef();
    const [callCount, setCallCount] = useState(0);

    useEffect(() => {
        latestCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        if (callCount > 0) {
            const fire = () => {
                setCallCount(0);
                latestCallback.current();
            };

            const id = setTimeout(fire, delay);
            return () => clearTimeout(id);
        }
    }, [callCount, delay]);

    return () => setCallCount(callCount => callCount + 1);
}
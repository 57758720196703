import streamStyles from "./stream.module.less";
import React from "react";

export default function StreamBlankContentItems({numItems, itemType, className, style}) {
    let elements = Array(parseInt(numItems)).fill({}).map((_, idx) => {
        const key = `blank_item_${idx}`;
        return itemType === "desktop" ?
            (
                <li key={key}>
                    <div className={streamStyles.streamContent}>
                        <div className={streamStyles.blankImage}></div>
                        <p className={streamStyles.blankTitle}></p>
                    </div>
                    <p className={streamStyles.blankStreamInfo}></p>
                </li>
            ) :
            (
                <li key={key}>
                    <div className={streamStyles.blankDate}/>
                    <div className={streamStyles.blankTitle}/>
                </li>
            );
    });
    return (
        <ul className={streamStyles.items} style={style}>
            {elements}
        </ul>
    )

}

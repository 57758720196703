import '!css-loader?{"url":false,"sourceMap":true}!less-loader?{"sourceMap":true}!./mobile_stream.css';
import React, {useEffect, useState} from 'react';
import StreamTitle from './streamTitle';
import StreamContentItems from './streamContentItems';
import StreamBlankContentItems from './streamBlankContentItems';
import StreamFooter from './streamFooter';
import {usePrevious} from "$Scripts/functions";
import mobileStyles from './stream.module.less';

export default function MobileStreamBlock(props) {
    const [selected, setSelected] = useState("news");
    const prevSelected = usePrevious(selected);

    const [data, setData] = useState({});
    const prevData = usePrevious(data);

    const [displayState, setDisplayState] = useState({
        show: true, showNext: true, showArchive: false, showProgress: true
    });

    const [chapters] = useState(window.gk.navbar.chapters.reduce((chapters, chapter) => {
        return Object.assign({}, chapters, {
            [chapter.ID]: chapter
        });
    }));

    const [regions] = useState(window.gk.navbar.regions.reduce((regs, reg) => {
        const prev = Object.assign({}, regs, {
            [reg.ID]: {'TITLE': reg.TITLE_REGION, 'HREF': reg.HREF_REGION}
        });
        let si = {};
        if (reg.subitems) {
            si = reg.subitems.reduce((subItems, subItem) => {
                return Object.assign({}, subItems, {
                    [subItem[2]]: {
                        'TITLE': subItem[0],
                        'HREF': subItem[1]
                    }
                });
            }, {});
        }
        return Object.assign({}, prev, si);
    }));

    function _handleTabClick(item) {
        setSelected(item)
    }

    function _loadNextPage(item) {
        const itemConfig = props.types.find(itm => {
            return itm['key'] === item;
        });
        const params = Object.assign({},
            itemConfig.params, {
                page: data[item] ? data[item].page : itemConfig.params.page
            });
        const url = (props.lang === 'ukr') ? props.url : '/' + props.lang + props.url;
        const request = url + "?" + (new URLSearchParams(params)).toString();

        setDisplayState((prevState) => {
            return Object.assign({}, prevState, {
                showNext: false,
                showProgress: true
            });
        });

        fetch(request)
            .then(response => response.json())
            .then(({page, items, type, banners}) => {
                setData((prevData) => {
                    const prevItems = prevData[item] ? prevData[item]['items'] || [] : [];
                    const singleItem = {
                        page: page + 1,
                        items: [].concat(prevItems, items),
                        banners: banners,
                    };
                    return Object.assign({}, prevData, {
                        [item]: singleItem
                    });
                });

                setDisplayState(prev => Object.assign({}, prev, {showNext: true, showProgress: false}));

                if (typeof props.onNextPage == 'function') {
                    props.onNextPage.call(this, {
                        page: page,
                        type: type,
                        viewtype: 'mobile'
                    })
                }
            }).catch(error => console.error('Mobile stream exception:', error));
    }

    function _renderContent() {
        if (!data[selected]) {
            return (
                <div>
                    <StreamBlankContentItems
                        numItems="10"
                        itemType="mobile"
                        className="items">
                    </StreamBlankContentItems>
                </div>
            )
        }
        const keys = Object.getOwnPropertyNames(data);

        return (keys.map(item => {
            const style = {
                display: selected === item ? 'block' : 'none'
            };
            const showConfig = {
                archive: displayState.showArchive,
                progress: displayState.showProgress,
                next: displayState.showNext
            };

            const items = data[item].items.map(article => {
                return Object.assign({}, article, {
                    chapter: chapters[article.parent],
                    region: regions[article.region]
                });
            });

            return (
                <div>
                    <StreamContentItems
                        onAfterDisplayItems={props.onAfterDisplayItems}
                        items={items}
                        banners={data[item].banners}
                        itemType="mobile"
                        className={mobileStyles.items}/>
                    <StreamFooter archive_url="/archive/"
                                  showConfig={showConfig}
                                  item={item}
                                  autoload={true}
                                  autoloadTimeout={3000}
                                  className={mobileStyles.loadmore}
                                  itemType="mobile"
                                  onDoLoad={_loadNextPage}
                    >
                        <StreamBlankContentItems
                            numItems={10}
                            itemType="mobile"
                        >
                        </StreamBlankContentItems>
                    </StreamFooter>
                </div>
            )
        }));
    };


    useEffect(() => {
        if (prevSelected !== selected) {
            _loadNextPage(selected);
        }
    }, [selected]);

    return displayState.show ? (
        <div className={mobileStyles.mobileStream}>
            <StreamTitle titles={props.types}
                         selected={selected}
                         onChange={_handleTabClick}
            />
            {_renderContent()}
        </div>
    ) : null;
};


import React, {useState, useEffect} from 'react';
import Dropdown from "$Scripts/components/dropdown/dropdown";

export default function WeatherWidget(props) {
    const [active, setActive] = useState(false);
    const [data, setData] = useState([]);
    const [currentCity, setCurrentCity] = useState(props['city'] || 0);

    useEffect(() => {
        fetch(props.url)
            .then(response => response.json())
            .then((data) => {
                setData((prevState) => data);
            }).catch(error => console.error('WeatherWidget exception:', error));
    }, []);

    function _handleItemClick(item) {
        // setCookie("weatherCity", item, {expires: 60 * 60 * 24 * 360, path: "/"});
        setCurrentCity(item);
        setActive(false);
    }

    let elems = Object.keys(data).map((key) => {
        return (
            <li key={key}
                onClick={() => _handleItemClick(key)}>
                {data[key].name[props.lang]}
            </li>
        );
    });

    return (
        <Dropdown
            active={active}
            title={
                <span onClick={() => setActive(!active)}>
                    {
                        !!data[currentCity] ?
                        <>
                            {data[currentCity]['name'][props.lang]}&nbsp;
                            {data[currentCity]['afternoon']['temperature']} &deg;
                        </> : ''
                    }
                </span>
            }
            content={
                <ul>
                    {elems}
                </ul>
            }
        />
    )
}
import streamStyles from "./stream.module.less";
import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import classNames from 'classnames/bind';
import moment from 'moment';
import 'moment/locale/uk';

const cx = classNames.bind(streamStyles);

function StreamContentItem(props) {
    if(!props){
        console.error("Props not defined in StreamContentItem!!!!");
        return null;
    }
    const item = props.item;

    const statusElementStyle = (item.status['color'] && item.status['name']) ?
        {backgroundColor: item.status.color} : null;

    const statusClass = cx({
        streamStatus: props.type === 'mobile'
    });

    const statusElement = (item.status['color'] && item.status['name']) ? (
        <span className={statusClass} style={statusElementStyle}>
                {item.status.name}
        </span>
    ) : (
        <span> </span>
    );

    const regionElement = !!item.regions ? (
        <a href={item.region.HREF}>
            {item.region.TITLE}
        </a>
    ) : (
        <span></span>
    );

    const chapterElement = !!item.chapter ? (
        <a href={props.item.chapter.HREF}>
            {props.item.chapter.TITLE}
        </a>
    ) : (
        <span></span>
    );
    
    const displayRelative  = moment().diff(item.date,'hours') < 1;
    const itemClass = cx({
        top : !!props.item.status['top'],
        mobile: props.type === 'mobile',
        desktop: props.type === 'desktop'
    });

    if (props.type === "mobile") {
        return (
            <li className={itemClass}>
                <div className={streamStyles.streamDate}>
                    {item.date.format("HH:mm")}
                </div>
                <div className={streamStyles.streamTitle}>
                    {statusElement}
                    <a href={item.href}>{item.title}</a>
                </div>
            </li>
        )
    } else if (props.type === 'desktop') {
        return (
            <li className={itemClass}>
                <div className={streamStyles.streamContent}>
                    <div className={streamStyles.streamImage}>
                        <img onClick={() => {
                            window.location.href = item.href
                        }}
                             src={item.img + '_56x56_to.jpg'}
                             width="56" height="56"/>
                    </div>
                    <div className={streamStyles.streamLink}>
                        <a href={item.href} className={streamStyles.streamTitle}>
                            {item.title}
                        </a>&nbsp;
                    </div>
                </div>
                <ul className={streamStyles.streamInfo}>
                    <li className={streamStyles.streamDate}>
                        {displayRelative ? item.date.fromNow(true) : item.date.format("HH:mm")}
                    </li>
                    <li className={streamStyles.streamRegion}>
                        {regionElement}
                    </li>
                    <li className={streamStyles.streamChapter}>
                        {chapterElement}
                    </li>
                    <li className={streamStyles.streamStatus}>
                        {statusElement}
                    </li>
                    <li className={streamStyles.streamViews}>
                        <i className="fa-solid fa-eye"></i>&nbsp;{item.views}
                    </li>
                </ul>
            </li>
        )
    }
}
function StreamDateItem(props){
    const date = moment(props.date,'HH:mm, DD-MM-YYYY');
    return (
        <li className={streamStyles.dateSeparator}>{date.format("dddd, DD.MM.YYYY")}</li>
    )
}


export default function StreamContentItems(props) {
    moment.locale('uk');

    function _renderBanner(banners, idx, key) {
        return (
            <li key={key}
                style={{padding: 0}}
                className={streamStyles.advertising}
                dangerouslySetInnerHTML={{__html: banners[idx]}}/>
        )
    }

    useEffect(() => {
        if (typeof props.onAfterDisplayItems == 'function') {
            props.onAfterDisplayItems.call(this, ReactDOM.findDOMNode(this));
        }
    }, [props]);

    useEffect(() => {
        if (typeof props.onAfterDisplayItems == 'function') {
            props.onAfterDisplayItems.call(this, ReactDOM.findDOMNode(this));
        }
    }, []);

    const items = props.items.map((item) => {
        item.date = moment(item.date,'HH:mm, DD-MM-YYYY');
        return (
            <StreamContentItem
                key={item.dir}
                item={item}
                type={props.itemType}
            />
        )
    });

    let contentItems = [];
    let prevItem;
    items.forEach((item, index) => {
        if(!prevItem){
            contentItems.push(<StreamDateItem date={item.props.item.date} />);
        } else if(prevItem && !prevItem.props.item.date.isSame(item.props.item.date, 'day')){
            contentItems.push(<StreamDateItem date={item.props.item.date} />);
        }
        contentItems.push(item);
        prevItem = item;
    });

    let elements = [];
    if (!props.banners) {
        elements = [].concat(contentItems);
    } else {
        let new_elements = [];
        let bannerIdx = 0;
        let bannerId = 0;
        contentItems.forEach((itm, idx) => {
            new_elements.push(itm);
            if ((idx - 4) % 10 === 0) {
                new_elements.push(_renderBanner(props.banners, bannerIdx++, bannerId++));
                bannerIdx = bannerIdx % props.banners.length;
            }
        });
        elements = [].concat(new_elements);
    }

    return (
        <ul className={props.className}>
            {elements}
        </ul>
    )

}

import React from "react";
import filterStyles from './stream.module.less';

/**
 * StreamTitle
 */
export default function StreamTitle(props) {

    const elements = props.titles.map(item => {
        return (
            <li key={item.key}
                className={props.selected === item.key ? filterStyles.active : ''}
                onClick={() => props.onChange(item.key)}>
                <span>{item.title}</span>
            </li>
        );
    });

    return (
        <ul className={filterStyles.filter}>
            {elements}
        </ul>
    );
}

import React from 'react';
import { createRoot } from 'react-dom/client';


import '$Styles/components/sidebar.less';

import DesktopStreamBlock from '$Scripts/components/stream/desktop_stream.jsx';
import LanguageDisplay from '$Scripts/components/language.jsx';
import NavPanel from '$Scripts/components/nav/nav.jsx';
import MobileStreamBlock from "$Scripts/components/stream/mobile_stream.jsx";
import HeaderElements from "$Scripts/components/menu";

$(function ($) {
    const config_desktop = [
        {key: 'news', title: 'Усі Новини', params: {type: 'news', page: 1, display: 1, per_page: 10}},
        {
            key: 'transkarpathia',
            title: 'Закарпаття',
            params: {type: 'transkarpathia', page: 1, display: 1, per_page: 10}
        },
        {key: 'top', title: 'Головне', params: {type: 'top', page: 1, display: 1, per_page: 10}},
    ];

    const desktopNews = createRoot(document.getElementById('desktop-news'));
    desktopNews.render(<DesktopStreamBlock url="/ajax/type_stream/"
                                           types={config_desktop}
                                           lang={gk.lang}
                                           onAfterDisplayItems={gk.callbacks.afterStreamDisplayItems}
                                           onNextPage={gk.callbacks.nextPage} />);

    const menuLeft = createRoot(document.querySelector('.js-header-elements'));
    menuLeft.render(<HeaderElements config={gk}/>);

    const languageDisplay = createRoot(document.querySelector('.js-language'));
    languageDisplay.render(<LanguageDisplay config={gk}/>);

    const navPanel = createRoot(document.querySelector('.js-nav-sidebar-container'));
    navPanel.render(<NavPanel config={gk}/>);

    let mobile_tabs = document.querySelectorAll('.js-mobile-tabs');
    const config_mobile = [
        {key: 'news', title: 'Усі Новини', params: {type: 'news', page: 1, display: 1}},
        {key: 'transkarpathia', title: 'Закарпаття', params: {type: 'transkarpathia', page: 1, display: 1}},
        {key: 'top', title: 'Головне', params: {type: 'top', page: 1, display: 1}},
    ];

    let mtElements = [];
    Array.from(mobile_tabs).forEach((item,index) => {
        createRoot(item).render(
            <MobileStreamBlock
                url="/ajax/type_stream/"
                types={config_mobile}
                onAfterDisplayItems={gk.callbacks.afterStreamDisplayItems}
                onNextPage={gk.callbacks.nextPage}
                lang={gk.lang}>
            </MobileStreamBlock>);
    });


    $('.js-toggle-nav').on('click', function () {
        $('body').toggleClass('nav-show');
    });

});
import styles from './dropdown.module.less';
import classNames from "classnames/bind";
import React from 'react';

const cx = classNames.bind(styles);

export default function dropdown({title, content, className, titleClassName, contentClassName, showIcon = true, active = false}) {
    const style = cx({
        dropdownBlock: true,
        active: active,
        [className]: !!className
    });

    const buttonStyle = cx({
        dropdownButton: true,
        [titleClassName]: !!titleClassName
    });
    const contentStyle = cx({
        dropdownContent: true,
        [contentClassName]: !!contentClassName
    });

    return (
        <div className={style}>
            <div className={buttonStyle}>
                {title}
                {showIcon ? (<>&nbsp;<i className="fa-solid fa-caret-down"></i></>) : null }
            </div>
            <div className={contentStyle}>
                {content}
            </div>
        </div>
    )
}
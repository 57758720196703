import React,{useState, useRef, useEffect} from 'react';
import style from './searchbox.module.less';
import Dropdown from "$Scripts/components/dropdown/dropdown";

export default function SearchWidget(props) {
    const quickSearchUrl = props['quickSearchUrl'] || '/ajax/type_quicksearch';
    const searchPageUrl =(props.lang === 'ukr') ? props.url : props.lang + '/' + props.url;
    const timerId = useRef(0);

    const [results, setResults] = useState([]);
    const [dropdown, setDropdown] = useState(false);
    const [query, setQuery] = useState("");


    function _handleClick(item) {
        window.location.href = item['href'] ? item['href'] : item;
    }

    const quickHints = results.map((item) => {
        return (
            <li key={item.dir} className={style.listItem} onClick={() => _handleClick(item)}>
                {item.title}
            </li>
        )
    });

    function _handleKeyUp(evt) {
        if (evt.keyCode === 27) {
            setResults((prevState) => []);
            setDropdown(false);
            setQuery("");
        }
        if (evt.keyCode === 13) {
            window.location.href = searchPageUrl + '#query=' + query;
        }
    }

    useEffect(() => {
        if(query.length === 0){
            setResults([]);
        }
    },[query]);

    useEffect(() => {
        setDropdown(results.length > 0);
    },[results])

    function _handleChange(evt) {
        const text = evt.target.value;
        setQuery(prevState => text);
        clearTimeout(timerId.current);
        if(text.length > 3){
            timerId.current = setTimeout(() => {
                _handleSearch()
            }, props.timeout);
        }
    }

    function _handleSearch() {
        console.log('start quicksearch', query);
        if (!query) {
            // setResults([]);
            // setDropdown(false);
            return;
        }
        fetch(quickSearchUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({q: query})
        }).then(response => response.json()).then((data) => {
            setResults((prevState) => data);
            // setDropdown(true);
        }).catch(error => {
            console.error('SearchWidget exception:', error)
            setResults([]);
            // setDropdown(false);
        });
    }


    return (
        <Dropdown
            active={dropdown}
            className={style.searchBox}
            titleClassName={style.title}
            contentClassName={style.container}
            showIcon={false}
            title={
                (
                    <>
                        <form action={searchPageUrl}
                              method="GET"
                              onSubmit={(evt) => _handleSubmit(evt)}>
                            <button type="submit" className={style.submit}>
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </button>
                            <input
                                value={query}
                                autoComplete="off"
                                type="text"
                                name="query"
                                className="searchbox-form__text"
                                placeholder={props.title}
                                onChange={(evt) => _handleChange(evt)}
                                onKeyUp={(evt) => _handleKeyUp(evt)}
                            />
                        </form>
                    </>
                )
            }

            content={
                <ul>
                    {quickHints}
                    <li key="more" className={style.listItem} onClick={() => _handleClick(searchPageUrl)}>...</li>
                </ul>
            }
        />
    )
}